import React from 'react';
import Layout from '@hoc/layouts/Layout';
import Calendar from '@components/Dashboard/Calendar';
import ActiveBookings from '@components/Dashboard/ActiveBookings';
import Statistics from '@components/Dashboard/Statistics';

const DashboardPage = () => {
  return (
    <Layout>
      <div className="background-container">
        <div className="background-image"></div>
        <div className="content grid grid-cols-4 gap-x-5 p-3">
          <Statistics />
          <Calendar />
          <ActiveBookings />
        </div>
      </div>
    </Layout>
  );
};

export default DashboardPage;
