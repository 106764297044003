import React from 'react';

const Input = ({ placeholder, onChange, error, value, className, required, label, ...props }) => {
  return (
    <div>
      {label && (
        <div className="pl-1 pb-0.5 text-sm">
          {label} <span className="text-red-500">{required && '*'}</span>
        </div>
      )}
      <input
        value={value}
        className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm ${className}`}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
      {error && <div className={`text-xs text-red-400 pt-2`}>{error}</div>}
    </div>
  );
};

export default Input;
