import React, { useState } from 'react';
import Input from '@core/inputs/Input';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import AuthLayout from '@hoc/layouts/AuthLayout';
import useFormErrors from '@hooks/useFormErrors';
import { showError, showSuccess } from '@utils/helpers';
import API from '@utils/plugins/API';
import resetPasswordValidator from '@utils/validators/resetPasswordValidator';
import { useHistory } from 'react-router-dom';

const ResetPasswordPage = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const email = sessionStorage.getItem('email');
  const { getError, validateErrors, clearErrors } = useFormErrors();
  const history = useHistory();

  const handleSubmit = () => {
    const errors = validateErrors({ confirmationCode, newPassword }, resetPasswordValidator);
    if (errors) {
      return;
    }
    setIsLoading(true);
    API.post('/confirmReset', { code: confirmationCode, newPassword, email })
      .then((res) => {
        showSuccess(res.data.message);
        history.push('/login');
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthLayout>
      <h1 className="text-2xl text-center font-semibold">Forgot Password</h1>
      <div className="space-y-2 pb-2">
        <Input
          value={confirmationCode}
          onChange={setConfirmationCode}
          placeholder="Confirmation Code"
          label="Confirmation Code"
          required
          error={getError('confirmationCode')}
          onFocus={clearErrors}
        />
        <Input
          value={newPassword}
          onChange={setNewPassword}
          type="password"
          placeholder="Password"
          label="Password"
          required
          error={getError('newPassword')}
          onFocus={clearErrors}
        />
      </div>
      <SubmitButton label="Reset Password" isLoading={isLoading} onClick={handleSubmit} />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
