import React, { useEffect, useState } from 'react';
import API from '@utils/plugins/API';
import { FaCalendarCheck } from 'react-icons/fa';
import { MdStarRate } from 'react-icons/md';

const Statistics = () => {
  const [overallRating, setOverallRating] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);

  useEffect(() => {
    API.get('/keeper/getStatistics').then((res) => {
      const { overallRating, totalBookings } = res.data;
      setOverallRating(overallRating);
      setTotalBookings(totalBookings);
    });
  }, []);

  return (
    <div>
      <h1 className="text-xl font-medium mb-3">Statistics</h1>
      <div className="flex items-center space-x-1.5 mb-1">
        <FaCalendarCheck size={20} className="text-primary" />
        <div>Total Bookings: {totalBookings}</div>
      </div>
      <div className="flex items-center space-x-1.5 mb-1">
        <MdStarRate size={20} className="text-primary" />
        <div>Average Score: {overallRating}</div>
      </div>
    </div>
  );
};

export default Statistics;
