const changePasswordValidator = {
  oldPassword: {
    presence: {
      allowEmpty: false,
      message: "Old password can't be blank"
    }
  },
  newPassword: {
    presence: {
      allowEmpty: false,
      message: "New password can't be blank"
    }
  },
  confirmPassword: {
    presence: {
      allowEmpty: false,
      message: "Confirm password can't be blank"
    },
    equality: {
      attribute: 'newPassword',
      message: 'Confirm password must match new password'
    }
  }
};

export default changePasswordValidator;
