import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '@core/inputs/Input';
import AuthLayout from '@hoc/layouts/AuthLayout';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import useFormErrors from '@hooks/useFormErrors';
import changePasswordValidator from '@utils/validators/changePasswordValidator';
import API from '@utils/plugins/API';
import { showError } from '@utils/helpers';

const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { clearErrors, getError, validateErrors } = useFormErrors();

  const changePassword = () => {
    const errors = validateErrors(
      { oldPassword, newPassword, confirmPassword },
      changePasswordValidator
    );
    if (errors) {
      return;
    }
    setIsLoading(true);
    API.post('/changePassword', { oldPassword, newPassword })
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <AuthLayout maxWidth="max-w-md">
      <h2 className="mt-5 text-center text-3xl font-medium text-gray-900">Change Password</h2>
      <div className="space-y-3">
        <Input
          label="Old Password"
          required
          type={'password'}
          placeholder={'Old Password'}
          value={oldPassword}
          onChange={setOldPassword}
          onFocus={clearErrors}
          error={getError('oldPassword')}
        />
        <Input
          label="New Password"
          required
          type={'password'}
          placeholder={'New Password'}
          value={newPassword}
          onChange={setNewPassword}
          onFocus={clearErrors}
          error={getError('newPassword')}
        />
        <Input
          label="Confirm New Password"
          required
          type={'password'}
          error={getError('confirmPassword')}
          placeholder={'Confirm New Password'}
          value={confirmPassword}
          onChange={setConfirmPassword}
          onFocus={clearErrors}
        />
      </div>
      <SubmitButton
        isLoading={isLoading}
        fullWidth
        label="Change Password"
        onClick={changePassword}
      />
    </AuthLayout>
  );
};

export default ChangePasswordPage;
