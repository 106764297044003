import React from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import logo from '@assets/images/logo.png';

const LandingPage = () => {
  const navigateToClientPage = () => {
    window.location.href = 'https://putrat.net';
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center w-screen h-screen">
      <div className="flex items-center my-3 md:my-0 w-screen md:w-[60vw] justify-center">
        <div>
          <img src={logo} className="w-60 h-60 mx-auto" alt="logo" />
          <div>
            <div className="text-2xl font-medium">Welcome to Putrat Keeper</div>
            <div className="text-lg font-normal">Happy Pets, Happy People</div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-screen md:w-auto flex-1 bg-primary h-screen justify-center">
        <div className="space-y-2 my-3 md:my-0 flex flex-col">
          <DefaultButton
            bgColor="bg-white"
            bgColorHover="hover:bg-white"
            textColor="text-black"
            link
            to="/login"
            label="Login"
          />
          <DefaultButton
            bgColorHover="hover:bg-white"
            bgColor="bg-white"
            onClick={navigateToClientPage}
            textColor="text-black"
            label="Login as client"
          />
          <DefaultButton
            bgColorHover="hover:bg-white"
            bgColor="bg-white"
            link
            to="/register"
            label="Register"
            textColor="text-black"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
