import React, { useEffect, useState } from 'react';
import API from '@utils/plugins/API';
import moment from 'moment/moment';
import { FaCheck } from 'react-icons/fa';
import { TiCancel } from 'react-icons/ti';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import { showError, showSuccess } from '@utils/helpers';

const formatDate = (date, format = 'DD.MM.YYYY') => moment(date).format(format);

const ActiveBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [isUserCanceling, setIsUserCanceling] = useState(false);
  const [isUserConfirming, setIsUserConfirming] = useState(false);

  const getData = () => {
    API.get('/keeper/getActiveBookings')
      .then((res) => {
        setBookings(res.data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getData();
  }, []);

  const cancelBooking = (bookingId) => {
    setIsUserCanceling(true);
    API.post(`/booking/cancel/${bookingId}`)
      .then((res) => {
        showSuccess(res.data.message);
        getData();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsUserCanceling(false);
      });
  };

  const confirmBooking = (bookingId) => {
    setIsUserConfirming(true);
    API.post(`/booking/confirm/${bookingId}`)
      .then((res) => {
        showSuccess(res.data.message);
        getData();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsUserConfirming(false);
      });
  };

  return (
    <div className="max-h-120 overflow-y-auto">
      <div className="text-xl text-center font-medium mb-3">Active bookings</div>
      {bookings.map((booking, index) => (
        <div className="border-b py-5 flex items-center justify-between" key={index}>
          <div>
            <div className="text-lg">Booking with {booking.personname}</div>
            <div className="capitalize">{booking.status}</div>
            <div className="flex items-center justify-between">
              {booking.type.toLowerCase() === 'keeper' ? (
                <div>
                  {formatDate(booking.fromdate)} -> {formatDate(booking.todate)}
                </div>
              ) : (
                <div>
                  {formatDate(booking.startdate)} -> {booking.starttime}-{booking.endtime}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center">
            {booking.status === 'pending' && (
              <SubmitButton
                bgColor="bg-green-500"
                bgColorHover="hover:bg-green-600"
                xs
                disabledBgColor="disabled:bg-green-500"
                isLoading={isUserConfirming}
                label={<FaCheck size={16} />}
                onClick={() => confirmBooking(booking.bookingid)}
                extraClasses="mr-2"
              />
            )}
            {booking.status !== 'cancelled' && (
              <SubmitButton
                bgColor="bg-red-400"
                bgColorHover="hover:bg-red-500"
                xs
                disabledBgColor="disabled:bg-red-400"
                isLoading={isUserCanceling}
                onClick={() => cancelBooking(booking.bookingid)}
                label={<TiCancel size={16} />}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActiveBookings;
