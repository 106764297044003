import React, { useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import 'tippy.js/dist/tippy.css';
import CalendarBooking from '@components/Dashboard/CalendarBooking';

const CalendarDay = ({ day, monthStart }) => {
  const [isSeatClicked, setIsSeatClicked] = useState(false);

  const handleDateClick = () => {
    if (day.isSame(monthStart, 'month')) {
      setIsSeatClicked(true);
    }
  };
  return (
    <Tippy
      interactive
      visible={isSeatClicked}
      onClickOutside={() => setIsSeatClicked(false)}
      render={() => <CalendarBooking day={day} isSeatClicked={isSeatClicked} />}>
      <div
        className={`flex items-center justify-center py-2 ${
          day.isSame(monthStart, 'month') && 'cursor-pointer hover:bg-gray-100'
        } ${isSeatClicked && 'bg-gray-100'} rounded`}
        onClick={handleDateClick}>
        {day.isSame(monthStart, 'month') && day.format('D')}
      </div>
    </Tippy>
  );
};

export default CalendarDay;
