import React from 'react';
import { IoMdClose } from 'react-icons/io';

const BlankModal = ({
  setOpenModal,
  children,
  title,
  icon,
  maxWidth = 'max-w-lg',
  onClose,
  isOpen,
  bgColor = 'bg-white'
}) => {
  const close = () => {
    if (setOpenModal) {
      setOpenModal(false);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${!isOpen && 'hidden'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className={`inline-block self-center align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth} sm:w-full`}>
          <div className={`${bgColor} px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}>
            <div className="sm:flex sm:items-start">
              {icon && (
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                  {icon}
                </div>
              )}
              <div className="mt-3 sm:mt-0 sm:ml-4 text-left flex-1">
                <div className="flex items-center justify-between">
                  {title && (
                    <h3 className="text-lg text-center sm:text-left leading-6 font-medium text-gray-900">
                      {title}
                    </h3>
                  )}
                  <button
                    onClick={close}
                    className="hover:bg-gray-100 cursor-pointer text-gray-400 rounded-full p-2">
                    <IoMdClose size={20} />
                  </button>
                </div>
                <div className="mt-2 justify-center">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankModal;
