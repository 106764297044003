import { ADD_ME, REMOVE_ME, UPDATE_FULL_NAME } from '@constants/redux';

export const addMe = (payload) => {
  return { type: ADD_ME, payload };
};

export const removeMe = () => {
  return { type: REMOVE_ME };
};

export const updateFullName = (payload) => {
  return { type: UPDATE_FULL_NAME, payload };
};
