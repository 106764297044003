import React from 'react';
import Header from '@hoc/partials/Header';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Header />
      <div className="h-full flex-1 flex flex-row bg-gray-100">
        <div className="my-5 mx-2 bg-white w-full">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
