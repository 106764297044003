import React from 'react';
import Select from 'react-select';

const MultiSelect = ({
  label,
  required,
  options = [],
  value,
  placeholder,
  onChange,
  error,
  ...rest
}) => {
  return (
    <div>
      {label && (
        <div className="pl-1 pb-0.5 text-sm">
          {label} <span className="text-red-500">{required && '*'}</span>
        </div>
      )}
      <Select
        isMulti
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        {...rest}
      />
      {error && <div className={`text-xs text-red-400 pt-2`}>{error}</div>}
    </div>
  );
};

export default MultiSelect;
