import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Redirect, Route } from 'react-router-dom';
import { logout } from '@redux/authentication/Action';

const MyComponent = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) =>
    _.get(state, 'authenticationReducer.accessToken', false)
  );
  const isSubscriptionActive = useSelector((state) =>
    _.get(state, 'meReducer.subscriptionActive', false)
  );
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && isSubscriptionActive) {
          return <Component {...props} />;
        }
        dispatch(logout());
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }}
    />
  );
};

export default MyComponent;
