import React from 'react';

const TextArea = (props) => {
  const { label, placeholder, handleInputChange, error, onFocus, value, required, className } =
    props;
  return (
    <div>
      {label && (
        <div className="pl-1 pb-0.5 text-sm">
          {label} <span className="text-red-500">{required && '*'}</span>
        </div>
      )}
      <textarea
        autoComplete="off"
        className={`pl-2 focus:border-blue-500 border-brand-none placeholder-light-gray
                    appearance-none bg-transparent border-1 border-gray-300
                    w-full text-gray-700 text-base mr-3 pt-2
                    pb-1 leading-tight focus:outline-none rounded
                    resize-none ${className}
                 `}
        rows="4"
        onChange={(e) => handleInputChange(e.target.value)}
        onClick={onFocus}
        value={value}
        placeholder={placeholder}
      />
      {error && <div className={`text-xs text-red-400 pt-2`}>{error}</div>}
    </div>
  );
};

export default TextArea;
