import React, { useEffect, useState } from 'react';
import API from '@utils/plugins/API';
import moment from 'moment/moment';
import { FaSpinner } from 'react-icons/fa';
import { CgSpinner } from 'react-icons/cg';

const CalendarBooking = ({ day, isSeatClicked }) => {
  const [name, setName] = useState('');
  const [numberOfPets, setNumberOfPets] = useState('');
  const [typeOfPet, setTypeOfPet] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  useEffect(() => {
    if (isSeatClicked) {
      setIsLoading(true);
      API.get('/keeper/getDateInfo', { params: { date: day.format('YYYY-MM-DD') } })
        .then((res) => {
          setIsBooked(true);
          const { name, numberofpets, typeofpet, startdate, enddate } = res.data;
          setName(name);
          setNumberOfPets(numberofpets);
          setTypeOfPet(typeofpet);
          setStartDate(startdate);
          setEndDate(enddate);
        })
        .catch((err) => {
          setIsBooked(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isSeatClicked]);

  return (
    <div className="px-5 py-3 rounded-lg border bg-white">
      {isLoading ? (
        <CgSpinner className="animate-spin text-primary" size={20} />
      ) : isBooked ? (
        <>
          <div className="text-lg font-medium text-center">{day.format('MMMM Do')}</div>
          <div>Booking with: {name}</div>
          <div>
            Pets: {typeOfPet}, {numberOfPets}
          </div>
          <div>
            From/To: {moment(startDate).format('DD.MM.YYYY')} ->{' '}
            {moment(endDate).format('DD.MM.YYYY')}
          </div>
        </>
      ) : (
        <>No booking found in this date</>
      )}
    </div>
  );
};

export default CalendarBooking;
