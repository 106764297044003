const EditProfileValidator = {
  fullName: {
    presence: {
      allowEmpty: false,
      message: "Full Name can't be blank"
    },
    type: 'string'
  },
  idNumber: {
    presence: {
      allowEmpty: false,
      message: "ID number can't be blank"
    },
    type: 'string'
  },
  address: {
    presence: {
      allowEmpty: false,
      message: "Address can't be blank"
    },
    type: 'string'
  },
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: "Phone number can't be blank"
    },
    format: {
      pattern: /^(((067|068|069)\d{7})){1}$/,
      message: 'Invalid Phone Number'
    },
    type: 'string'
  },
  typeOfPets: {
    presence: {
      allowEmpty: false,
      message: "Pet types can't be blank"
    },
    length: {
      minimum: 1,
      message: 'You must have at least on type of pet'
    }
  },
  numberOfPets: {
    presence: {
      allowEmpty: false,
      message: "Number of pets can't be blank"
    },
    numericality: {
      greaterThan: 0,
      message: 'Number of pets must be greater than or equal to 1'
    }
  },
  isKeeper: {
    atLeastOneTrue: {
      message: 'Keeper must check at least one of these fields'
    }
  },
  isWalker: {
    atLeastOneTrue: {
      message: 'Keeper must check at least one of these fields'
    }
  }
};

export default EditProfileValidator;
