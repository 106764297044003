import React from 'react';
import { logout } from '@redux/authentication/Action';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';
import _ from 'lodash';
import NavItem from '@core/navbar/NavItem';
import { IoIosChatbubbles } from 'react-icons/io';

const Header = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const fullName = useSelector((state) => _.get(state, 'meReducer.fullName', ''));

  return (
    <nav className="bg-white">
      <div className="relative flex items-center justify-between h-16 px-6">
        <Link to="/dashboard" className="text-base font-mono">
          Putrat Keeper UI
        </Link>
        <div className="flex items-center space-x-3">
          <NavItem icon={IoIosChatbubbles} title="Chat" link to="/chat" />
          <div className="flex justify-end py-3">
            <Menu as="div" className="relative">
              <Menu.Button className="flex items-center space-x-1 rounded-md">
                <div className="relative">
                  <FaCircle className="text-primary text-4xl" />
                  <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white">
                    {fullName.substring(0, 1).toUpperCase()}
                  </span>
                </div>
                <span>{fullName}</span>
              </Menu.Button>
              <Menu.Items className="absolute right-0 bg-white rounded-md shadow-lg border whitespace-nowrap z-[999]">
                <Menu.Item>
                  <Link
                    to="/edit-profile"
                    className="hover:bg-gray-100 no-underline block px-4 py-2 text-sm text-gray-700 cursor-pointer">
                    Edit Profile
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/change-password"
                    className="hover:bg-gray-100 no-underline block px-4 py-2 text-sm text-gray-700 cursor-pointer">
                    Change Password
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className="hover:bg-gray-100 block px-4 border-t py-2 text-sm text-gray-700 cursor-pointer"
                    onClick={handleLogout}>
                    Log Out
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
