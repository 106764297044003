import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import GuestRoute from '@router/GuestRoute';
import LoginPage from '@pages/LoginPage';
import LandingPage from '@pages/LandingPage';
import RegisterPage from '@pages/RegisterPage';
import DashboardPage from '@pages/DashboardPage';
import ChatPage from '@pages/ChatPage';
import EditProfilePage from '@pages/EditProfilePage';
import ChangePasswordPage from '@pages/ChangePasswordPage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import SubscribedRoute from '@router/SubscribedRoute';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage} />
        <GuestRoute exact path="/register" component={RegisterPage} />
        <SubscribedRoute exact path="/dashboard" component={DashboardPage} />
        <SubscribedRoute exact path="/chat" component={ChatPage} />
        <SubscribedRoute exact path="/edit-profile" component={EditProfilePage} />
        <SubscribedRoute exact path="/change-password" component={ChangePasswordPage} />
        <GuestRoute exact path="/forgot-password" component={ForgotPasswordPage} />
        <GuestRoute exact path="/reset-password" component={ResetPasswordPage} />
        <GuestRoute exact path="/" component={LandingPage} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
