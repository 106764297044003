// AUTH
export const ADD_AUTH = 'ADD_AUTH';
export const REMOVE_AUTH = 'REMOVE_AUTH';

// ME
export const ADD_ME = 'ADD_ME';
export const REMOVE_ME = 'REMOVE_ME';
export const UPDATE_FULL_NAME = 'UPDATE_FULL_NAME';

// SPINNER
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_KEEPER = 'UPDATE_KEEPER';
