import React, { useRef, useState } from 'react';
import Input from '@core/inputs/Input';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import useFormErrors from '@hooks/useFormErrors';
import API from '@utils/plugins/API';
import { showError, showSuccess } from '@utils/helpers';
import RegisterValidator from '@utils/validators/registerValidator';
import Select from '@core/Select';
import { animalTypes, citiesInAlbania } from '@constants/index';
import EmailVerificationModal from '@components/Signup/EmailVerificationModal';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import AuthLayout from '@hoc/layouts/AuthLayout';
import TextArea from '@core/textareas/TextArea';
import MultiSelect from '@core/inputs/MultiSelect';
import AddButton from '@core/buttons/atoms/AddButton';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [description, setDescription] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [images, setImages] = useState([]);
  const imageInputRef = useRef();
  const { clearErrors, validateErrors, getError } = useFormErrors();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [viewEnterConfirmationCodeModal, setViewEnterConfirmationModal] = useState(false);
  const [typeOfPets, setTypeOfPets] = useState([]);
  const [numberOfPets, setNumberOfPets] = useState(1);
  const [isKeeper, setIsKeeper] = useState(false);
  const [isWalker, setIsWalker] = useState(false);

  const handleImageAdd = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImages((prevState) => [...prevState, e.target.result]);
        imageInputRef.current.value = null;
      };

      reader.readAsDataURL(file);
    }
  };

  const deleteImage = (imageIndex) => {
    setImages((prevState) => {
      return prevState.filter((_, index) => index !== imageIndex);
    });
  };

  const handleSubmit = async () => {
    const payload = {
      email,
      fullName,
      password,
      address,
      phoneNumber,
      description,
      idNumber,
      isKeeper,
      isWalker,
      numberOfPets,
      images,
      typeOfPets: typeOfPets.map(({ label }) => label)
    };
    const errors = validateErrors(payload, RegisterValidator);
    if (errors) {
      return;
    }

    setIsLoading(true);
    API.post('/keeper/register', payload)
      .then((res) => {
        setViewEnterConfirmationModal(true);
        showSuccess(res.data.message);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addButtonClick = () => {
    clearErrors();
    imageInputRef.current.click();
  };

  return (
    <AuthLayout maxWidth="max-w-7xl">
      <h1 className="text-2xl text-center font-semibold">Register</h1>
      <div className="grid md:grid-cols-2 p-3 gap-5">
        <div>
          <div className="text-red-400 pb-1">{getError('images')}</div>
          <AddButton
            label="Add an image"
            className="p-1.5 bg-emerald-400 rounded text-white mb-2"
            onClick={addButtonClick}
          />
          {images.length ? (
            <Carousel>
              {images.map((image, index) => (
                <div className="relative" key={index}>
                  <img src={image} alt="image" className="aspect-[4.5/3] object-cover" />
                  <DeleteButton
                    disabled={images.length === 1}
                    className="absolute top-4 left-6 p-1.5 bg-red-500 text-white"
                    onClick={() => deleteImage(index)}
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <p>No image has been uploaded</p>
          )}
          <input
            accept="image/jpeg, image/png, image/gif, image/jpg"
            type="file"
            onChange={handleImageAdd}
            className="hidden"
            ref={imageInputRef}
          />
        </div>
        <div>
          <div className="grid md:grid-cols-2 gap-3 mb-3">
            <Input
              required
              placeholder="Full Name"
              label="Full Name"
              error={getError('fullName')}
              value={fullName}
              onChange={setFullName}
              onFocus={clearErrors}
            />
            <Input
              required
              placeholder="Email"
              label="Email"
              value={email}
              onChange={setEmail}
              error={getError('email')}
              onFocus={clearErrors}
            />
            <Select
              required
              label="Address"
              placeholder="Please select a city"
              options={citiesInAlbania.map((city) => ({ label: city, value: city }))}
              value={address}
              onChange={setAddress}
              error={getError('address')}
              onFocus={clearErrors}
            />
            <Input
              required
              label="Phone Number"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              error={getError('phoneNumber')}
              onFocus={clearErrors}
            />
            <Input
              required
              label="ID Number"
              placeholder="ID Number"
              value={idNumber}
              onChange={setIdNumber}
              onFocus={clearErrors}
              error={getError('idNumber')}
            />
            <MultiSelect
              required
              value={typeOfPets}
              label="Type Of Pets"
              options={animalTypes}
              placeholder="Please select a pet type"
              onFocus={clearErrors}
              error={getError('typeOfPets')}
              onChange={setTypeOfPets}
            />
            <Input
              required
              type="number"
              placeholder="Number Of Pets"
              label="Number Of Pets"
              value={numberOfPets}
              min={1}
              onChange={setNumberOfPets}
              onFocus={clearErrors}
              error={getError('numberOfPets')}
            />
            <Input
              required
              label="Password"
              placeholder="Password"
              value={password}
              type="password"
              onChange={setPassword}
              error={getError('password')}
              onFocus={clearErrors}
            />
          </div>
          <div className="mb-2">
            <div className="grid grid-cols-2 border p-5 rounded-lg">
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={isKeeper}
                  onChange={() => setIsKeeper((prevState) => !prevState)}
                />
                <div className="text-xl">Keeper</div>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={isWalker}
                  onChange={() => setIsWalker((prevState) => !prevState)}
                />
                <div className="text-xl">Walker</div>
              </div>
            </div>
            {Boolean(getError('isKeeper') || getError('isWalker')) && (
              <div className="text-sm text-red-400 pt-1">
                {getError('isKeeper') || getError('isWalker')}
              </div>
            )}
          </div>
          <TextArea
            className="mb-3"
            placeholder="Description"
            label="Description"
            value={description}
            handleInputChange={setDescription}
            onFocus={clearErrors}
          />
          <SubmitButton fullWidth label="Register" onClick={handleSubmit} isLoading={isLoading} />
        </div>
      </div>
      <EmailVerificationModal
        isOpen={viewEnterConfirmationCodeModal}
        email={email}
        setOpenModal={setViewEnterConfirmationModal}
      />
    </AuthLayout>
  );
};

export default RegisterPage;
