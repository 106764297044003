import { CLEAR_FILTERS, UPDATE_FILTERS, UPDATE_KEEPER } from '@constants/redux';

export const updateFilters = (payload) => {
  return { type: UPDATE_FILTERS, payload };
};

export const clearFilters = () => {
  return { type: CLEAR_FILTERS };
};

export const updateKeeperId = (keeperId) => {
  return { type: UPDATE_KEEPER, payload: keeperId };
};
