import React, { useState } from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import API from '@utils/plugins/API';
import { authenticate } from '@redux/authentication/Action';
import { showError } from '@utils/helpers';
import { Link, useHistory } from 'react-router-dom';
import useFormErrors from '@hooks/useFormErrors';
import { useDispatch } from 'react-redux';
import Input from '@core/inputs/Input';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import loginValidator from '@utils/validators/loginValidator';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { validateErrors, clearErrors, getError } = useFormErrors();

  const onSubmit = async (e) => {
    e.preventDefault();
    const errors = validateErrors({ email, password }, loginValidator);
    if (errors) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await API.post('/keeper/login', { email, password });
      const { subscriptionStatus, paymentUrl } = response.data;

      if (subscriptionStatus) {
        const { keeper, tokenData } = response.data;
        dispatch(authenticate(tokenData, keeper));
        history.push('/dashboard');
      } else {
        window.location.href = paymentUrl;
      }
    } catch (e) {
      showError(e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout maxWidth="max-w-md">
      <div>
        <h2 className="mt-5 text-center text-3xl font-bold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div className="rounded-md shadow-sm space-y-3">
          <Input
            value={email}
            error={getError('email')}
            placeholder={'Email address'}
            onChange={setEmail}
            onFocus={clearErrors}
          />
          <Input
            error={getError('password')}
            type={'password'}
            value={password}
            placeholder={'Password'}
            onChange={setPassword}
            onFocus={clearErrors}
          />
          <div className="text-center">
            <Link to={`/forgot-password?email=${email}`}>Forgot Password?</Link>
          </div>
        </div>
        <SubmitButton isLoading={isLoading} type="submit" label="Sign in" fullWidth />
      </form>
    </AuthLayout>
  );
};

export default LoginPage;
