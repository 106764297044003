import React, { useEffect, useState } from 'react';
import Layout from '@hoc/layouts/Layout';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { socket } from '../socket';
import Chat from '@components/Chat/Chat';

const ChatPage = () => {
  const [rooms, setRooms] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [correspondentName, setCorrespondentName] = useState(null);
  const userId = useSelector((state) => _.get(state, 'meReducer.keeperid', ''));

  useEffect(() => {
    socket.connect();
    socket.emit('get rooms', userId);
    socket.on('list of rooms', (data) => {
      setRooms(data);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleChange = (room) => {
    setRoomId(room.bookingid);
    setCorrespondentName(room.correspondentName);
  };

  return (
    <Layout>
      <div className="flex items-end h-full">
        <div className="h-full w-30 md:w-50 lg:w-60 bg-primary overflow-y-scroll">
          <div className="text-base md:text-lg lg:text-xl font-medium text-white pl-5 pt-5">
            Chats
          </div>
          {rooms.map((room) => (
            <div
              className={`text-white pl-5 py-3 ${
                roomId === room.bookingid && 'bg-primary-200'
              } mt-2 cursor-pointer text-sm md:text-base lg:text-lg`}
              onClick={() => handleChange(room)}>
              {room.correspondentName}
            </div>
          ))}
        </div>
        <div className="flex-1 h-full relative">
          <Chat roomId={roomId} correspondentName={correspondentName} />
        </div>
      </div>
    </Layout>
  );
};

export default ChatPage;
