import React from 'react';
import CalendarDay from '@components/Dashboard/CalendarDay';
import moment from 'moment/moment';

const Calendar = () => {
  const currentDate = moment();

  const monthStart = currentDate.clone().startOf('month');
  const monthEnd = currentDate.clone().endOf('month');
  const startDate = monthStart.clone().startOf('week');
  const endDate = monthEnd.clone().endOf('month');

  const days = [];
  let day = startDate.clone();

  while (day.isSameOrBefore(endDate)) {
    days.push(day);
    day = day.clone().add(1, 'day');
  }

  return (
    <div className="border p-3 col-span-2 bg-white">
      <h2 className="text-xl font-bold text-center">{monthStart.format('MMMM yyyy')}</h2>
      <div className="mt-10">
        <div className="grid grid-cols-7 gap-1">
          {days.map((day, index) => (
            <CalendarDay day={day} monthStart={monthStart} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
