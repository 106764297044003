import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ icon: Icon, title, to, link = false, onClick }) => {
  const className = `flex items-center p-1.5 rounded-lg cursor-pointer ${
    title && 'md:space-x-0.5'
  }`;

  const iconClassName = 'text-3xl text-primary';

  if (link) {
    return (
      <Link to={to} className={className}>
        <Icon className={iconClassName} />
        <span className="hidden md:block">{title}</span>
      </Link>
    );
  }

  return (
    <div onClick={onClick} className={className}>
      <Icon className={iconClassName} />
      <span className="hidden md:block">{title}</span>
    </div>
  );
};

export default NavItem;
